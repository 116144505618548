import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import React from "react";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useTranslation } from 'react-i18next';
import { StyledHomeContent, StyledHomeTitle, StyledDocs, StyledDocsItem, StyledDocsContent } from "../styled";
import DocSDK from "../../../assets/images/doc-sdk.svg";
import DocMarket from "../../../assets/images/doc-market.svg";
import DocSmartContract from "../../../assets/images/doc-smart-contract.svg";
import { useBreakpoint, useGA } from "../../../hooks";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LOCALES } from "../../../locales";
export var Docs = function Docs() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;
  var lang = isZh ? '' : 'en';
  var indicatorStyles = {
    display: 'inline-block',
    width: 28,
    height: 28,
    borderRadius: '50%',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#FFFFFF',
    background: '#D1D1E1'
  };
  var docs = [{
    icon: DocSmartContract,
    link: 'https://github.com/consenlabs/tokenlon-contracts'
  }, {
    icon: DocSDK,
    link: 'https://tokenlon-v5-sdk.docs.imvercel.works/'
  }, {
    icon: DocMarket,
    link: "https://docs.token.im/tokenlon-onboarding/".concat(lang)
  }];
  return __jsx(StyledDocs, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeTitle, null, t('docs_title')), !isBreakPointLG ? __jsx(StyledDocsContent, null, docs.map(function (_ref, index) {
    var icon = _ref.icon,
        link = _ref.link;
    return __jsx(StyledDocsItem, {
      key: index,
      href: link,
      target: "_blank",
      "data-trackid": "webtl_homepage_developerbanner",
      "data-trackdata": JSON.stringify({
        title: t("doc_".concat(index + 1, "_title")),
        url: link,
        index: index
      }),
      onClick: trackElClick
    }, __jsx("img", {
      src: icon,
      alt: "docs icon"
    }), __jsx("div", null, __jsx("h3", {
      className: "item-title"
    }, t("doc".concat(index + 1, "_title"))), __jsx("p", {
      className: "item-desc"
    }, t("doc".concat(index + 1, "_desc")))));
  })) : __jsx(Carousel, {
    showThumbs: false,
    axis: "horizontal",
    showIndicators: true,
    showStatus: false,
    showArrows: false,
    autoPlay: false,
    emulateTouch: true,
    renderIndicator: function renderIndicator(onClickHandler, isSelected, index, label) {
      if (isSelected) {
        return __jsx("li", {
          style: _objectSpread(_objectSpread({}, indicatorStyles), {}, {
            background: '#1C1C1E'
          }),
          "aria-label": "Selected: ".concat(label, " ").concat(index + 1),
          title: "Selected: ".concat(label, " ").concat(index + 1)
        }, index + 1);
      }

      return __jsx("li", {
        style: indicatorStyles,
        onClick: onClickHandler,
        onKeyDown: onClickHandler,
        value: index,
        key: index,
        role: "button",
        tabIndex: 0,
        title: "".concat(label, " ").concat(index + 1),
        "aria-label": "".concat(label, " ").concat(index + 1)
      }, index + 1);
    }
  }, docs.map(function (_ref2, index) {
    var icon = _ref2.icon,
        link = _ref2.link;
    return __jsx(StyledDocsItem, {
      key: index,
      href: link,
      target: "_blank",
      "data-trackid": "webtl_homepage_developerbanner",
      "data-trackdata": JSON.stringify({
        title: t("doc_".concat(index + 1, "_title")),
        url: link,
        index: index
      }),
      onClick: trackElClick
    }, __jsx("img", {
      src: icon,
      alt: "docs icon"
    }), __jsx("div", null, __jsx("h3", {
      className: "item-title"
    }, t("doc".concat(index + 1, "_title"))), __jsx("p", {
      className: "item-desc"
    }, t("doc".concat(index + 1, "_desc")))));
  }))));
};