import React from "react";
var __jsx = React.createElement;
import { StyledBenefits, StyledHomeContent, StyledHomeTitle, StyledBenefitsContent, StyledBenefitsItem } from "../styled";
import { useTranslation } from 'react-i18next';
import { insertLink } from "../../../utils/insertLink";
import BenefitFigure1 from "../../../assets/images/benefit-gasless-trading.svg";
import BenefitFigure2 from "../../../assets/images/benefit-trade-price.svg";
import BenefitFigure3 from "../../../assets/images/benefit-security.svg";
import BenefitFigure4 from "../../../assets/images/benefit-success-rate.svg";
import BenefitFigure5 from "../../../assets/images/benefit-reward.svg";
import BenefitFigure6 from "../../../assets/images/benefit-customer-support.svg";
var BenefitsFigures = [BenefitFigure1, BenefitFigure2, BenefitFigure3, BenefitFigure4, BenefitFigure5, BenefitFigure6];
export var Benefits = function Benefits() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(StyledBenefits, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeTitle, null, t('benefits_title')), __jsx(StyledBenefitsContent, null, BenefitsFigures.map(function (figure, index) {
    var idx = index + 1;
    var desc = idx === 6 ? insertLink(t("benefit".concat(idx, "_desc")), /support@tokenlon.im/g, 'mailto:support@tokenlon.im') : t("benefit".concat(idx, "_desc"));
    return __jsx(StyledBenefitsItem, {
      key: idx
    }, __jsx("img", {
      src: figure,
      alt: "benefit".concat(idx, "_title")
    }), __jsx("div", null, __jsx("h3", {
      className: "item-title"
    }, t("benefit".concat(idx, "_title"))), __jsx("p", {
      className: "item-desc"
    }, desc)));
  }))));
};