import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { StyledHomeContent, StyledHomeHow, StyledHomeHowContent, StyledHomeHowItem, StyledHomeTitle } from "../styled";
import WorkFigure1 from "../../../assets/images/work-icon-1.svg";
import WorkFigure2 from "../../../assets/images/work-icon-2.svg";
import WorkFigure3 from "../../../assets/images/work-icon-3.svg";
var WorkFigures = [WorkFigure1, WorkFigure2, WorkFigure3];
export var How = function How() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(StyledHomeHow, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeTitle, null, t('how_it_works_title')), __jsx(StyledHomeHowContent, null, WorkFigures.map(function (figure, index) {
    var idx = index + 1;
    return __jsx(StyledHomeHowItem, {
      key: idx
    }, __jsx("img", {
      src: figure,
      alt: "work icon"
    }), __jsx("div", null, __jsx("p", {
      className: "item-title"
    }, t("work_title".concat(idx))), __jsx("p", null, t("work_desc".concat(idx)))));
  }))));
};