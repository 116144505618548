import React from "react";
var __jsx = React.createElement;
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNews, useGA } from "../../../hooks";
import { StyledHomeContent, StyledHomeNews, StyledHomeNewsHeader, StyledHomeNewsTitle, StyledHomeNewsContent, StyledHomeNewsItem } from "../styled";
import { useBreakpoint } from "../../../hooks";
export var News = function News() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us';
  var news = useNews();

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  if (!(news !== null && news !== void 0 && news.length)) {
    return null;
  }

  var lastNews = isBreakPointLG ? news.splice(0, 1) : news;
  return __jsx(StyledHomeNews, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeNewsHeader, null, __jsx(StyledHomeNewsTitle, null, t('latest_news')), __jsx("a", {
    className: "help-center",
    href: "https://tokenlon.zendesk.com/hc/".concat(lang, "/sections/360006093572")
  }, __jsx("span", {
    "data-trackid": "webtl_homepage_newsmore",
    onClick: trackElClick
  }, t('view_all'), " \u2192"))), __jsx(StyledHomeNewsContent, null, lastNews === null || lastNews === void 0 ? void 0 : lastNews.map(function (news, index) {
    var showContent = !isBreakPointLG && index === 0;
    return __jsx(StyledHomeNewsItem, {
      className: showContent ? 'first' : '',
      key: news.id,
      href: news.html_url,
      "data-trackid": "webtl_homepage_newsbanner",
      "data-trackdata": JSON.stringify({
        title: news.title,
        url: news.html_url,
        index: index
      }),
      onClick: trackElClick
    }, __jsx("img", {
      src: news.thumbnail,
      alt: news.title
    }), __jsx("p", {
      className: "news-title"
    }, news.title), __jsx("p", {
      className: "news-date"
    }, dayjs(news.edited_at).format('MM/DD/YYYY')), showContent && __jsx("p", {
      className: "news-digest"
    }, news.digest));
  }))));
};