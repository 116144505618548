import React from "react";
var __jsx = React.createElement;
import { StyledServices, StyledHomeContent, StyledHomeTitle, StyledServicesContent, StyledServicesItem } from "../styled";
import ServiceLimitOrder from "../../../assets/images/service-limitorder.svg";
import ServiceTokenlomics from "../../../assets/images/service-tokenlomics.svg";
import ServiceWallet from "../../../assets/images/service-wallet.svg";
import { useTranslation } from 'react-i18next';
import { ASSET_PREFIX } from "../../../constants/env";
import { LOCALES } from "../../../locales";
import { useGA } from "../../../hooks";
export var Services = function Services() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;
  var lang = isZh ? 'zh-cn' : 'en-us';
  var targetLitepaper = "".concat(ASSET_PREFIX, "/files/").concat(isZh ? 'Tokenlon-Litepaper_zh-cn' : 'Tokenlon-litepaper_en-us', ".pdf");
  return __jsx(StyledServices, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeTitle, null, t('services_title')), __jsx(StyledServicesContent, null, __jsx(StyledServicesItem, null, __jsx("div", {
    className: "main"
  }, __jsx("h3", null, t('service1_title')), __jsx("p", null, t('service1_desc')), __jsx("a", {
    href: "https://support.tokenlon.im/hc/".concat(lang, "/articles/7463636612116"),
    "data-trackid": "webtl_homepage_servicebanner",
    "data-trackdata": JSON.stringify({
      title: t('service1_link'),
      url: "https://support.tokenlon.im/hc/".concat(lang, "/articles/7463636612116"),
      index: 0
    }),
    onClick: trackElClick
  }, t('service1_link'), " \u2192")), __jsx("div", {
    className: "placeholder"
  }), __jsx("img", {
    src: ServiceLimitOrder,
    className: "layer2",
    alt: "Layer2"
  })), __jsx(StyledServicesItem, null, __jsx("h3", null, t('service2_title')), __jsx("p", null, t('service2_desc')), __jsx("a", {
    href: "/lon",
    style: {
      marginRight: '24px'
    },
    "data-trackid": "webtl_homepage_servicebanner",
    "data-trackdata": JSON.stringify({
      title: t('service1_link'),
      url: '/lon',
      index: 1
    }),
    onClick: trackElClick
  }, t('service2_link'), " \u2192"), __jsx("a", {
    href: targetLitepaper,
    "data-trackid": "webtl_homepage_servicebanner",
    "data-trackdata": JSON.stringify({
      title: t('service2_link_2'),
      url: targetLitepaper,
      index: 2
    }),
    onClick: trackElClick
  }, t('service2_link_2'), " \u2192"), __jsx("img", {
    src: ServiceTokenlomics,
    className: "tokenlomics",
    alt: "Tokenlomics"
  })), __jsx(StyledServicesItem, null, __jsx("h3", null, t('service3_title')), __jsx("p", null, t('service3_desc')), __jsx("a", {
    href: "https://token.im/",
    "data-trackid": "webtl_homepage_servicebanner",
    "data-trackdata": JSON.stringify({
      title: t('service3_link'),
      url: 'https://token.im/',
      index: 4
    }),
    onClick: trackElClick
  }, t('service3_link'), " \u2192"), __jsx("img", {
    src: ServiceWallet,
    className: "wallet",
    alt: "Wallet"
  })))));
};